import React from "react";
import Layout from "../components/Layout";
import SEO from '../components/seo';
import FormContato from '../components/Forms/FormContato';
import Navbar from "../components/Navbar";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEnvelope,faPhone,faClock} from "@fortawesome/free-solid-svg-icons";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

const ContatoPage = () => {

  return (
    <Layout>
      <SEO 
          title='Entre em contato'
          description='Acompanhe e desenvolva as COMPETÊNCIAS e HABILIDADES que seus colaboradores necessitam para potencializar os resultados de sua empresa.'
      />
	<div className="w-full lg:w-4/5 px-8 lg:px-0">
        <Navbar/>
	</div>

	<div className="flex items-center justify-center w-full min-h-screen p-10 lg:p-0">

		<FormContato/>				
		
	</div>

    </Layout>
  )
}

export default ContatoPage
