import { Link } from "gatsby";
import React from "react";
import Notification from '../components/Parts/Notification';

export default function Navbar(){
    return(
        <div>
            <div className="flex flex-row items-center justify-between py-8">
                <div>
                    <Link to="https://shelfin.com.br/" className="lg:block hidden">
                        <img className="h-10" src="/images/logo_escura.png" alt="Shelfin"/>
                    </Link>
                    <Link to="https://shelfin.com.br/" className="block lg:hidden">
                        <img className="h-20" src="/images/icon.png" alt="Shelfin"/>
                    </Link>
                </div>
                <div>
                    <div style={{ fontSize:'18px' }} className="flex flex-row space-x-2 items-center">
                       
                        <div className="relative group z-50">
                          <div className="text-gray-700 bg-gray-200 hover:bg-gray-300 transition delay-150 duration-300 ease-in-out rounded-lg px-4 py-2 flex flex-row items-center space-x-2"><div>Serviços</div></div>
                          <div className="bg-gray-100 rounded-xl group-hover:flex absolute w-44 hidden -left-2 py-4">
                            <div className="w-full flex-col"> 
                                <Link to="/recrutamento" className="text-gray-700 hidden hover:bg-gray-200 rounded-lg px-4 py-2 lg:block">Recrutamento</Link> 
                                <Link to="/headhunter" className="text-gray-700 hidden hover:bg-gray-200 rounded-lg px-4 py-2 lg:block">Headhunter</Link>
                            </div>
                          </div>
                        </div>  
                        <Link to="/#porque" className="text-gray-700 hidden hover:bg-gray-200 rounded-lg px-4 py-2 lg:block">Por que a Shelfin?</Link>
                        {/* <Link to="https://revista.shelfin.com.br/" className="font-bold text-gray-700 hidden lg:block">Revista Shelfin</Link> */}
                        <Link to="https://app.shelfin.com.br/" className="hidden lg:block rounded-lg hover:bg-gray-200 px-4 py-2">Entrar</Link>
                        {/*
                            <Link to="/use-shelfin" className="text-gray-700 rounded-lg px-4 py-2 hover:bg-gray-200 underline hover:no-underline">Começar agora</Link>
                            */}
                        
                    </div>
                </div>
            </div>
        </div>
    );
}
